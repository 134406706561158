import * as React from "react";

export function IconSoundOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      viewBox="0 0 50 50"
    >
      <path d="M 24.1875 3 C 23.277344 3 22.332031 3.4375 21.5625 4.21875 L 9.9375 15.8125 C 9.296875 16.378906 9 17.476563 9 18.25 L 9 31.75 C 9 32.515625 9.316406 33.609375 9.90625 34.125 L 21.5 45.6875 C 22.554688 46.761719 23.527344 47 24.15625 47 C 25.824219 47 27 45.476563 27 43.3125 L 27 6.3125 C 27 4.035156 25.539063 3 24.1875 3 Z M 3 15.96875 C 1.324219 15.96875 -0.03125 17.324219 -0.03125 19 L -0.03125 31 C -0.03125 32.675781 1.324219 34.03125 3 34.03125 L 7.46875 34.03125 C 7.140625 33.246094 7 32.410156 7 31.75 L 7 18.25 C 7 17.59375 7.164063 16.761719 7.5 15.96875 Z M 31.90625 18.96875 C 31.863281 18.976563 31.820313 18.988281 31.78125 19 C 31.40625 19.066406 31.105469 19.339844 31 19.703125 C 30.894531 20.070313 31.003906 20.460938 31.28125 20.71875 L 35.5625 25 L 31.28125 29.28125 C 30.882813 29.679688 30.882813 30.320313 31.28125 30.71875 C 31.679688 31.117188 32.320313 31.117188 32.71875 30.71875 L 37 26.4375 L 41.28125 30.71875 C 41.679688 31.117188 42.320313 31.117188 42.71875 30.71875 C 43.117188 30.320313 43.117188 29.679688 42.71875 29.28125 L 38.4375 25 L 42.71875 20.71875 C 43.042969 20.417969 43.128906 19.941406 42.933594 19.546875 C 42.742188 19.148438 42.308594 18.929688 41.875 19 C 41.652344 19.023438 41.441406 19.125 41.28125 19.28125 L 37 23.5625 L 32.71875 19.28125 C 32.511719 19.058594 32.210938 18.945313 31.90625 18.96875 Z"></path>
    </svg>
  );
}
