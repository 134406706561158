export const AppName = import.meta.env.VITE_APP_NAME;
export const infuraId = import.meta.env.VITE_INFURA_ID;
export const WalletConnectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID;
export const AppUrl = import.meta.env.VITE_APP_URL;
export const AppIcon = import.meta.env.VITE_APP_ICON;
export const StoreAccessTokenKey = "access_token";
export const StoreEmailKey = "email";
export const RefCodeKey = "referrer";


