import React from "react";

export default function IconFire(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      x="0px"
      y="0px"
      viewBox="0 0 460.734 460.734"
    >
      <path
        fill="#fe4f60"
        d="M365.619,150.521c66.22,85.68,55.28,205.28-16.34,267.47c-65.65,56.99-172.17,56.99-237.82,0	c-71.64-62.2-82.57-181.84-16.3-267.52c6.492-8.395,20.275-8.542,26.42,3.19c7.13,13.5,27.6,9.24,29.06-5.96	c5.291-55.17,20.542-101.083,54.77-142.07c8.88-10.63,26.36-5.32,27.5,8.48c0.86,10.27,3.43,20.96,8.82,31.25	c10.68,20.36,25.7,25.13,40.14,42.41c10.57,12.65,22.03,32.24,27.04,63.37c2.27,14.1,20.94,17.86,28.47,5.72	c0.74-1.19,1.45-2.44,2.13-3.76C344.739,142.981,358.649,141.511,365.619,150.521z"
      ></path>
      <path
        fill="#ffe773"
        d="M297.449,275.472c15.88,29.41,11.7,66.7-11.77,90.74c-33.48,34.28-88.52,23.43-112.85-3.89	c-19.74-22.16-16.83-52.38-15.57-65.51c3.02-31.4,18.21-56.41,34.27-74.61c8.8-9.97,25.06-1.81,22.46,11.23	c-4.05,20.31-1.48,37.69,8.36,48.43c15.734,17.184,38.28,14.764,52.8-7.2C280.589,266.421,292.759,266.791,297.449,275.472z"
      ></path>
      <path
        fill="#ff6d7a"
        d="M223.909,46.589c-5.219,7.137-9.875,15.155-13.54,24.143c-13.66,33.52-1.57,51.2-15,84	c-15.85,38.7-41.14,34.68-65,70c-17.606,26.062-25.296,64.286-21.238,104.634c3.006,29.888-39.359,39.052-48.588,10.466	c-19.632-60.808-8.822-133.2,34.615-189.36c6.492-8.395,20.291-8.537,26.42,3.19c7.13,13.5,27.6,9.24,29.06-5.96	c5.291-55.171,20.544-101.085,54.77-142.07c8.88-10.63,26.36-5.32,27.5,8.48C233.926,26.378,230.469,37.617,223.909,46.589z"
      ></path>
      <path
        fill="#fff0a6"
        d="M221.803,386.91c-19.314-2.612-37.461-11.662-48.974-24.589c-19.74-22.16-16.83-52.38-15.57-65.51	c2.695-28.022,16.007-54.647,34.445-74.805c6.834-7.472,18.862,0.83,14.404,9.922c-16.102,32.838-14.564,53.638-8.239,67.304	c12.167,26.273,43.643,29.534,51,57C253.461,373.362,238.94,389.228,221.803,386.91z"
      ></path>
    </svg>
  );
}
