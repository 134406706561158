import * as React from "react";

export function IconSoundOn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      viewBox="0 0 50 50"
    >
      <path d="M 26.609375 2 C 25.945313 2 25.304688 2.28125 24.855469 2.765625 L 12 16 L 12 34 L 24.855469 47.234375 C 25.304688 47.722656 25.945313 48 26.609375 48 C 27.925781 48 29 46.925781 29 45.609375 L 29 4.390625 C 29 3.074219 27.925781 2 26.609375 2 Z M 39.695313 10.421875 L 38.574219 12.074219 C 43.007813 14.679688 46 19.492188 46 25 C 46 30.507813 43.007813 35.320313 38.574219 37.925781 L 39.695313 39.578125 C 44.660156 36.609375 48 31.195313 48 25 C 48 18.804688 44.660156 13.390625 39.695313 10.421875 Z M 36.324219 15.378906 L 35.191406 17.042969 C 38.046875 18.554688 40 21.550781 40 25 C 40 28.449219 38.046875 31.445313 35.191406 32.957031 L 36.324219 34.621094 C 39.707031 32.738281 42 29.136719 42 25 C 42 20.863281 39.707031 17.261719 36.324219 15.378906 Z M 5 16 C 3.347656 16 2 17.347656 2 19 L 2 31 C 2 32.652344 3.347656 34 5 34 L 10 34 L 10 16 Z M 32.921875 20.386719 L 31.753906 22.109375 C 33.042969 22.445313 34 23.605469 34 25 C 34 26.394531 33.042969 27.554688 31.753906 27.890625 L 32.921875 29.613281 C 34.726563 28.855469 36 27.074219 36 25 C 36 22.925781 34.726563 21.140625 32.921875 20.386719 Z"></path>
    </svg>
  );
}
