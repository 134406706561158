import React from "react";

export default function IconSpaceship(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      x="0px"
      y="0px"
      viewBox="0 0 72 72"
    >
      <path d="M 57 11 C 53.77 11 37.114375 11.538969 26.484375 22.167969 C 25.577443 23.07502 24.786609 24.029773 24.085938 25.005859 C 23.287051 25.001878 17.991721 25.133756 13.847656 28.703125 C 9.5406563 32.413125 9.0478594 38.161687 9.0058594 38.804688 C 8.9488594 39.675687 9.2744844 40.526672 9.8964844 41.138672 C 10.460484 41.692672 11.217 42 12 42 C 12.082 42 12.163094 41.997234 12.246094 41.990234 L 19.042969 41.429688 C 19.140992 42.33223 19.524845 43.181095 20.171875 43.828125 L 28.171875 51.828125 C 28.818691 52.474941 29.668106 52.860283 30.570312 52.958984 L 30.009766 59.753906 C 29.938766 60.623906 30.248375 61.481516 30.859375 62.103516 C 31.426375 62.679516 32.198 63 33 63 C 33.065 63 33.130313 62.998141 33.195312 62.994141 C 33.838313 62.952141 39.587828 62.458344 43.298828 58.152344 C 46.858272 54.019017 46.993993 48.746875 46.994141 47.921875 C 47.970893 47.220776 48.924434 46.423348 49.832031 45.515625 C 60.462031 34.886625 61 18.231 61 15 C 61 12.791 59.209 11 57 11 z M 43 25 C 45.209 25 47 26.791 47 29 C 47 31.209 45.209 33 43 33 C 40.791 33 39 31.209 39 29 C 39 26.791 40.791 25 43 25 z M 16.960938 46.585938 C 15.045938 48.499938 13 52.242 13 57 C 13 58.104 13.896 59 15 59 C 19.758 59 23.499063 56.954062 25.414062 55.039062 L 22.585938 52.210938 C 22.348938 52.447938 21 53 19 53 C 19 51 19.552063 49.651063 19.789062 49.414062 L 16.960938 46.585938 z"></path>
    </svg>
  );
}
