import React from "react";

export default function IconTask(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <path d="M 11 1 L 11 3 L 13 3 L 13 1 L 11 1 z M 13 3 L 13 5 L 11 5 L 11 3 L 4 3 L 4 22 L 20 22 L 20 3 L 13 3 z M 15.292969 9.2929688 L 16.707031 10.707031 L 11 16.414062 L 7.2929688 12.707031 L 8.7070312 11.292969 L 11 13.585938 L 15.292969 9.2929688 z"></path>
    </svg>
  );
}
