import React from "react";

export default function IconFriends(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path d="M 10 3 A 3 3 0 0 0 7 6 A 3 3 0 0 0 10 9 A 3 3 0 0 0 13 6 A 3 3 0 0 0 10 3 z M 19 3 A 3 3 0 0 0 16 6 A 3 3 0 0 0 19 9 A 3 3 0 0 0 22 6 A 3 3 0 0 0 19 3 z M 9 11 C 7.3550302 11 6 12.35503 6 14 L 6 20 A 1.0001 1.0001 0 0 0 6.9472656 21.011719 L 6.9472656 27.101562 C 6.9472656 27.537562 7.4104688 28 7.9804688 28 C 8.5244688 28 8.9757188 27.578156 9.0117188 27.035156 L 9.4492188 20.464844 C 9.4672187 20.203844 9.6842656 20 9.9472656 20 C 10.210266 20 10.427312 20.204797 10.445312 20.466797 L 10.882812 27.035156 C 10.918813 27.577156 11.370063 28 11.914062 28 C 12.484063 28 12.947266 27.536797 12.947266 26.966797 L 12.947266 13 L 19 13 A 1.0001 1.0001 0 1 0 19 11 L 9 11 z M 21.976562 11.759766 C 21.982563 11.840766 22 11.918 22 12 C 22 13.657 20.657 15 19 15 L 15 15 L 15 20 C 15 20.553 15.448 21 16 21 L 16 26.966797 C 16 27.536797 16.463203 28 17.033203 28 C 17.577203 28 18.028453 27.578156 18.064453 27.035156 L 18.501953 20.464844 C 18.519953 20.203844 18.737 20 19 20 C 19.263 20 19.480047 20.204797 19.498047 20.466797 L 19.935547 27.035156 C 19.971547 27.577156 20.422797 28 20.966797 28 C 21.536797 28 22 27.536797 22 26.966797 L 22 21 C 22.552 21 23 20.553 23 20 L 23 14 C 23 13.106 22.599563 12.309766 21.976562 11.759766 z"></path>
    </svg>
  );
}
