import { useEffect, useState } from "react";
import { cn } from "../libs/utils.ts";
import { IconQuestionCircle } from "./NavHelpButton.tsx";
import { NavOrdinaryLink } from "./NavOrdinaryLink.tsx";
import { Navigation } from "./Navigation.tsx";
import { RegionSelector } from "./RegionSelector.tsx";
import { IconSettingsOutline } from "./icons/IconSettingsOutline.tsx";

import { useLocation } from "react-router-dom";
import { useMediaQuery, useScrollLock } from "usehooks-ts";
import useStore from "../hooks/store/useStore.ts";
import { LanguageSelectorMobile } from "./LanguageSelector.tsx";
import { IconDoubleChevronBack } from "./icons/IconDoubleChevronBack.tsx";
import { IconSearch } from "./icons/IconSearch.tsx";

function Overlay(props: { open: undefined | boolean; onClick: () => void }) {
  return (
    <div
      className={cn(
        "xl:hidden fixed overflow-hidden w-screen h-[100svh] bg-black/50 left-0 top-0 z-[15] xl:z-[20] ",
        { hidden: !props.open },
      )}
      onClick={props.onClick}
    />
  );
}

export function LeftSidebar(props: {
  open: boolean;
  closeRequested: () => void;
  headerHeight: number;
  scrollYPos: number;
}) {
  const isXl = useMediaQuery("(min-width: 1280px)");
  const [openOverlay, setOpenOverlay] = useState(props.open);
  const { toggleOpenState } = useStore((s) => ({
    toggleOpenState: s.toggleOpenState,
  }));
  const { lock, unlock } = useScrollLock({ autoLock: false });
  const isTelegramBot = useStore((state) => state.isTelegramBot);
  const loc = useLocation();

  useEffect(() => {
    if (!isXl) {
      if (props.open) {
        lock();
      } else {
        unlock();
      }
    }
    setOpenOverlay(props.open);
  }, [props.open]);

  if (isTelegramBot && loc.pathname.includes("/tapwar")) return null;

  return (
    <div>
      <Overlay
        open={openOverlay}
        onClick={() => {
          props.closeRequested && props.closeRequested();
        }}
      />
      <div
        className={cn(
          "w-[250px] left-0 flex absolute xl:relative z-[15] xl:z-[10] border-r xl:border-r-0 border-gray-800 xl:pl-0 xl:bg-transparent h-[100svh] transition-left duration-150",
          {
            "absolute top-0 -left-[280px] z-[6]  ": !openOverlay,
          },
        )}
      >
        <div className="fixed w-[250px] xl:left-0 flex flex-col flex-1 h-[100svh] bg-background border-r xl:border-none border-gray-800">
          <div className="flex-col py-[28px] pt-[18px] flex-1 flex ">
            <header className="flex gap-4 -ml-[5px] pl-8 -mt-[3px]">
              <a href="#" className="pt-[6px]">
                <img src="/images/logo-mark-w.svg" alt="logo" />
              </a>
              <RegionSelector />
              <div
                className="xl:hidden flex justify items-center flex-1 border-chinese-green justify-end pr-4 -mt-1"
                onClick={() => {
                  if (props.closeRequested) props.closeRequested();
                }}
              >
                <IconDoubleChevronBack className="text-gray-200 p-1" />
              </div>
            </header>

            {!isTelegramBot && (
              <div className="md:hidden pl-7 py-3 mt-5 border-b border-gray-800">
                <NavOrdinaryLink
                  href="#"
                  label="Search"
                  svg={<IconSearch width="20" />}
                  className="py-0"
                  onClick={() => {
                    toggleOpenState && toggleOpenState("searchSheet");
                  }}
                />
              </div>
            )}

            <Navigation />
            <div className="flex-1 flex flex-col justify-end pl-8">
              <LanguageSelectorMobile />
              <NavOrdinaryLink
                href="#"
                label="Settings"
                svg={<IconSettingsOutline />}
                className="relative font-jersey25 tracking-wider [&>span:nth-child(2)]:relative [&>span:nth-child(2)]:-top-[0px]"
                onClick={() => {
                  toggleOpenState && toggleOpenState("settingsSheet");
                }}
              />
              <NavOrdinaryLink
                href="https://docs.joint.exchange"
                target="_blank"
                label="Help & Support"
                svg={<IconQuestionCircle />}
                className="relative font-jersey25 tracking-wider [&>span:nth-child(2)]:relative [&>span:nth-child(2)]:-top-[0px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
