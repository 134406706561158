/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/P8J8srqte2L
 */

import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { memo, useEffect, useRef, useState } from "react";
import { ErrorIcon } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useConnectorContext } from "../../hooks/connectors/useConnectorContext";
import { usePointService } from "../../hooks/services/backend/usePointService";
import { useReferralService } from "../../hooks/services/backend/useReferralService";
import useMounted from "../../hooks/useMounted";
import { RequestError } from "../../libs/RequestError";
import { shortenAddress } from "../../libs/helpers";
import { FetchedUserHoverCard } from "../FetchedUserHoverCard";
import { MainContainerConnectButton } from "../MainContainerConnectButton";
import ScrollOverflowIndicator from "../ScrollOverflowIndicator";
import EmojiAvatar from "../avatar/EmojiAvatar";
import { AvatarSize } from "../avatar/useAvatar";
import { Card } from "../ui/card";
import { ScrollArea } from "../ui/scroll-area";

export function PointActivityStream() {
  const { getLatestPoints } = usePointService();
  const { getLatestPoints: getLatestReferralPoints } = useReferralService();
  const mounted = useMounted(1000);
  const { address } = useConnectorContext();
  const fromDate = useRef<string>("");
  const [points, setPoints] = useState<Point[]>([]);
  const loc = useLocation();
  const isPointsPage = loc.pathname.includes("/points");
  const isAirdropPage = loc.pathname.includes("/airdrop");

  const fetchLatest = useQuery({
    queryKey: [
      "getLatest",
      {
        limit: !fromDate.current ? 30 : 3,
        order: !fromDate.current ? "desc" : "asc",
        from: fromDate.current,
        referrer: isPointsPage || isAirdropPage ? undefined : address,
      },
    ],
    queryFn:
      isPointsPage || isAirdropPage ? getLatestPoints : getLatestReferralPoints,
    enabled: mounted && !!address,
    refetchInterval: 10000,
  });

  useEffect(() => {
    setPoints([]);
    fromDate.current = "";
  }, [loc]);

  useEffect(() => {
    if (fetchLatest.isLoading || fetchLatest.isRefetching || !fetchLatest.data)
      return;

    const data = fetchLatest.data;
    if (data.length == 0) return;

    setPoints([...data, ...points]);

    if (data.length) fromDate.current = data[0].createdAt;
  }, [fetchLatest.isLoading, fetchLatest.isRefetching, fetchLatest.isSuccess]);

  return (
    <div className="flex flex-col h-full relative">
      {address && !fetchLatest.isLoading && points.length == 0 && (
        <div className="flex h-full items-center justify-center font-light text-gray-400">
          No result found
        </div>
      )}

      {address && fetchLatest.isError && (
        <div className="flex items-center gap-2 text-red-300 justify-center font-light h-[300px] xl:h-[610px]">
          <ErrorIcon className="w-[20px]" />{" "}
          {(fetchLatest.error as RequestError)?.message}
        </div>
      )}

      {!address && <MainContainerConnectButton />}

      <ScrollOverflowIndicator
        side="bottom"
        className="relative h-full flex-1 [&>span.grad-indicator]:rounded-none"
      >
        <ScrollArea
          type="scroll"
          viewportClassName="absolute xl:relative"
          className="h-full absolute"
        >
          {<Comp points={points} isPointsPage />}
        </ScrollArea>
      </ScrollOverflowIndicator>
    </div>
  );
}

const Comp = memo(function Comp({
  points,
  isPointsPage,
}: {
  points: Point[];
  isPointsPage: boolean;
}) {
  return (
    <div className="mt-2 space-y-2">
      {points.map((point) => (
        <>
          {parseInt(point.point) == 0 && null}
          {parseInt(point.point) > 0 && (
            <Card
              key={`${point.address}_${point.point}_${point.createdAt}_${Date.now()}`}
              className="flex animate-in slide-in-from-top items-center tracking-wider justify-between p-2 bg-card-background border-card-border/40"
            >
              <div className="flex items-center space-x-4">
                <FetchedUserHoverCard
                  address={isPointsPage ? point.address : point.referee}
                >
                  <EmojiAvatar
                    size={AvatarSize.Small}
                    randomStr={isPointsPage ? point.address : point.referee}
                  />
                </FetchedUserHoverCard>

                <div>
                  <h2 className="text-sm font-medium text-gray-300">
                    {shortenAddress(
                      isPointsPage ? point.address : point.referee,
                    )}
                  </h2>
                  <p className="text-xs text-gray-500">
                    {moment(point.createdAt).fromNow()}
                  </p>
                </div>
              </div>
              <div className="text-right tracking-wider">
                <p className="text-sm font-normal text-gray-300">
                  {parseInt(point.point)} Points
                </p>
              </div>
            </Card>
          )}
        </>
      ))}
    </div>
  );
});
