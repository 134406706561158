export const imageList = [
  "/images/game/gamebg@1x.png",
  "/images/game/planet1.svg",
  "/images/game/planet2.svg",
  "/images/game/planet3.svg",
  "/images/game/planet4.svg",
  "/images/game/planet5.svg",
  "/images/game/planet6.svg",
  "/images/game/planet7.svg",
  "/images/game/planet8.svg",
  "/images/game/planet9.svg",
];

export const shipImages = [
  "/images/rank-ships/Cadet.png",
  "/images/rank-ships/Ensign.png",
  "/images/rank-ships/Lieutenant Jnr.png",
  "/images/rank-ships/Lieutenant.png",
  "/images/rank-ships/Commander.png",
  "/images/rank-ships/Captain.png",
  "/images/rank-ships/Commodore.png",
  "/images/rank-ships/Vice Admiral.png",
  "/images/rank-ships/Rear Admiral.png",
  "/images/rank-ships/Admiral.png",
];
