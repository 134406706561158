import React from "react";

export default function IconCoinGold(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
    >
      <defs>
        <linearGradient
          id="ecyDsS0kOAldu~rCsLCyGa_OFHwDWASQWmX_gr1"
          x1="6"
          x2="42"
          y1="32.5"
          y2="32.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#cc6700"></stop>
          <stop offset=".116" stopColor="#ce7507"></stop>
          <stop offset=".351" stopColor="#d38c13"></stop>
          <stop offset=".5" stopColor="#d49417"></stop>
          <stop offset="1" stopColor="#cc6700"></stop>
        </linearGradient>
        <linearGradient
          id="ecyDsS0kOAldu~rCsLCyGb_OFHwDWASQWmX_gr2"
          x1="12.308"
          x2="35.692"
          y1="34.692"
          y2="11.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#e07000"></stop>
          <stop offset=".144" stopColor="#e38309"></stop>
          <stop offset=".362" stopColor="#e79a15"></stop>
          <stop offset=".5" stopColor="#e8a219"></stop>
          <stop offset="1" stopColor="#e07000"></stop>
        </linearGradient>
        <linearGradient
          id="ecyDsS0kOAldu~rCsLCyGc_OFHwDWASQWmX_gr3"
          y1="15.472"
          y2="15.472"
          xlinkHref="#ecyDsS0kOAldu~rCsLCyGa"
        ></linearGradient>
        <linearGradient
          id="ecyDsS0kOAldu~rCsLCyGd_OFHwDWASQWmX_gr4"
          x1="11.621"
          x2="36.379"
          y1="35.379"
          y2="10.621"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f2ad00"></stop>
          <stop offset=".124" stopColor="#f6c43b"></stop>
          <stop offset=".249" stopColor="#fad86e"></stop>
          <stop offset=".358" stopColor="#fde794"></stop>
          <stop offset=".445" stopColor="#fef0ab"></stop>
          <stop offset=".5" stopColor="#fff3b3"></stop>
          <stop offset="1" stopColor="#f2ad00"></stop>
        </linearGradient>
        <linearGradient
          id="ecyDsS0kOAldu~rCsLCyGe_OFHwDWASQWmX_gr5"
          x1="17.177"
          x2="30.823"
          y1="30.945"
          y2="17.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f2ad00"></stop>
          <stop offset=".145" stopColor="#f6c43b"></stop>
          <stop offset=".29" stopColor="#fad86e"></stop>
          <stop offset=".416" stopColor="#fde794"></stop>
          <stop offset=".517" stopColor="#fef0ab"></stop>
          <stop offset=".581" stopColor="#fff3b3"></stop>
          <stop offset="1" stopColor="#f2ad00"></stop>
        </linearGradient>
      </defs>
      <g data-name="star coin">
        <path
          fill="url(#ecyDsS0kOAldu~rCsLCyGa_OFHwDWASQWmX_gr1)"
          d="M6,23v2c0,9.389,8.059,17,18,17s18-7.611,18-17V23Z"
        ></path>
        <ellipse
          cx="24"
          cy="23"
          fill="url(#ecyDsS0kOAldu~rCsLCyGb_OFHwDWASQWmX_gr2)"
          rx="17"
          ry="16.056"
        ></ellipse>
        <path
          fill="none"
          stroke="url(#ecyDsS0kOAldu~rCsLCyGc)"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M7,24C7,15.133,14.611,7.944,24,7.944S41,15.133,41,24"
        ></path>
        <path
          fill="url(#ecyDsS0kOAldu~rCsLCyGd_OFHwDWASQWmX_gr4)"
          d="M24,8c8.822,0,16,6.729,16,15S32.822,38,24,38,8,31.271,8,23,15.178,8,24,8m0-2C14.059,6,6,13.611,6,23s8.059,17,18,17,18-7.611,18-17S33.941,6,24,6Z"
        ></path>
        <path
          fill="url(#ecyDsS0kOAldu~rCsLCyGe_OFHwDWASQWmX_gr5)"
          d="M24.663,13.958,26.7,19.966h6.5a.7.7,0,0,1,.4,1.274l-5.233,3.649,2.014,5.949a.7.7,0,0,1-1.063.8L24,27.931l-5.315,3.706a.7.7,0,0,1-1.063-.8l2.014-5.949L14.4,21.24a.7.7,0,0,1,.4-1.274h6.5l2.034-6.008A.7.7,0,0,1,24.663,13.958Z"
        ></path>
      </g>
    </svg>
  );
}
