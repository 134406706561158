import React from "react";

export default function IconRank(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="20"
      {...props}
      viewBox="0 0 24 24"
    >
      <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M15.22,17.391 L12,15.45l-3.219,1.941c-0.378,0.228-0.843-0.111-0.743-0.54L8.89,13.19l-2.837-2.457C5.72,10.445,5.898,9.897,6.337,9.859 l3.743-0.32l1.461-3.455c0.172-0.406,0.746-0.406,0.918,0l1.46,3.446l3.743,0.32c0.439,0.038,0.617,0.585,0.284,0.874L15.11,13.18 l0.853,3.671C16.063,17.28,15.597,17.619,15.22,17.391z"></path>
    </svg>
  );
}
